import React, { useEffect, useRef } from "react";
import * as styles from "../styles/pages/WritingPage.module.scss";
import { useStaticQuery, graphql, Link } from "gatsby";
import { doBounceAnimation } from "../animations/twn_doBounceAnimation";
import { useWebMountContext } from "../context/PageLoadContext";

import { TagLabel } from "../components/Label/Label";

const WritingPage = () => {
  const { setFirstVisitToWritingPage } = useWebMountContext();

  const data = useStaticQuery(graphql`
    query MyQuery {
      allMdx(filter: { fileAbsolutePath: { regex: "content/blog/" }, frontmatter: {} }) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              date
              desc
              type
            }
          }
        }
      }
    }
  `);
  const pageRef = useRef();

  // Update Page Visit Context
  useEffect(() => {
    setFirstVisitToWritingPage(false);
  });

  useEffect(() => {
    doBounceAnimation(pageRef.current, 0.2);
  }, []);

  const allArticles = data.allMdx.edges;
  return (
    <div className={styles.page_wrapper} ref={pageRef}>
      <header>
        <div>
          <h1>Writings & Notes</h1>
          <p>A collection of thoughts, notes and hopefully some interesting stuffs.</p>
        </div>
      </header>

      <div>
        <div className={styles.numOfArticles_wrapper}>
          <span>{allArticles.length} Articles</span>
        </div>
        <ul className={styles.listing_grid}>
          {allArticles.map((article) => (
            <li key={article.node.id}>
              <CardArticle data={article} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WritingPage;

const CardArticle = ({ data }) => {
  const { title, date, slug, desc, type } = data.node.frontmatter;
  return (
    <article style={{ height: "100%" }} className={`card__hover`}>
      <div className={`${styles.card_container} shadow`}>
        <div className={styles.card_label}>
          <TagLabel type={type} />
        </div>
        <Link to={slug}>
          <h3>{title}</h3>
        </Link>
        <p>{desc}</p>
        <div className={styles.card_bottom_text}>
          <span className={styles.card_readMore}>Read more</span>
          <span className={styles.card_date}>{date}</span>
        </div>
      </div>
    </article>
  );
};
